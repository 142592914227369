<template>
<div>
 <div v-if="!auth.authenticated  && authstate.loading === false" class="container"> 
          <div> <p>Login with your Discord Credentials to view the "HeatMapFX Heatmap"</p> </div>
          <div> <p>If you are a member of the T-Bone discord server or a Paid Member, login for basic access</p> </div>
          <div class="btn btn-dark btn-lg active" v-on:click="auth.loginWithRedirect()" :disabled="auth.authenticated">Login</div>
          <hr>
        <!-- <div> Auth Level? : {{authLevel}} </div> -->
        <div > <p>If you are NOT a member of T-Bone start your trial here:</p></div>
        <div>
          <a href="https://launchpass.com/heatmapfx/paid-members" class="btn btn-secondary btn-lg active" role="button">
          Start your HeatMapFx Paid Trial Here</a>
        </div>
        <hr>
 </div>
        <div v-if="auth.authenticated && authLevel === 0 || authLevel === 1 || authLevel === 2 || authLevel === 3 || authLevel === 4" class="container">
          <!-- <div> Auth Level? : {{authLevel}} </div> -->
            <div class ="row datestyle">{{format_dateLong(browserDate)}}</div>
               <div class ="row">
                <div class = "col">
                  <table class="table table-striped table-hover table-sm caption-top">
                    <caption>Heatmap {{format_dateLong(etz)}}</caption>
                    <thead>  
                  <tr>
                      <th>CUR</th>
                      <th>D</th>
                      <th>H4</th>
                      <th>M30</th>
                      <th>M5</th>
                      <th>M1</th>
                      <th>SUM</th>
                  </tr>
                    </thead>
                    <tbody>
                  <tr v-for="item in info" v-bind:key="item.index">
                          <td>{{item.index}}</td> 
                          <td>{{item.D}}</td>
                          <td>{{item.H4}}</td>
                          <td>{{item.M30}}</td>
                          <td>{{item.M5}}</td>
                          <td>{{item.M1}}</td>
                          <td :class="{highlight: item.SUM > 19}">{{item.SUM}}</td>
                  </tr>
                    </tbody>
                  </table>
                </div>
                <div class = "col">
                  <table class = "table table-striped table-hover table-sm caption-top">
                    <caption v-if="ioprBRTime">Open Position Ratios Calc | upated: {{format_dateLong(this.ioprBRTime)}}</caption>
                    <thead>
                      <tr>
                        <td>CUR</td>
                        <td>L</td>
                        <td>S</td>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-for="inst in ioprBR" v-bind:key="inst.index">
                        <td>{{inst.CUR}}</td>
                        <td :class="{highlight: inst.L > 6}">{{inst.L}}</td>
                        <td :class="{highlight: inst.S > 6}">{{inst.S}}</td>
                        </tr>
                    </tbody>
                  </table>
                   </div>
                   <div class="col"></div>
            <!-- </div> -->
          <div class="row">
            <div class="col">
                <table class = "table table-striped table-hover table-sm caption-top">
                    <caption>ADR Left | updated: {{format_dateLong(iadrTime)}}</caption>
                    <thead>
                      <tr>
                        <td>Pair</td>
                        <td>ADR</td>
                        <td>Daily Range</td>
                        <td>Pips Left</td>
                        <td>% Left</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="inst in iadr" v-bind:key="inst.index">
                        <td >{{inst.pair}}</td>
                        <td >{{inst.sma10}}</td>
                        <td >{{inst.range}}</td>
                        <td >{{inst.pipsLeft}}</td>
                        <!-- <td :class="{adrGreen: inst.percentLeft > 0}">{{inst.percentLeft}}</td> -->
                        <td v-bind:class = "(inst.percentLeft > 0)?'adrGreen':'adrRed'">{{inst.percentLeft}}</td>
                        </tr>
                    </tbody>
                  </table>
              </div>
              <div class="col">
                 <table class="table table-hover caption-top">
                   <caption>21 EMA Strong / Weak Analysis | updated {{format_dateLong(iema21anaTime)}}</caption> 
                   <thead>
                     <tr>
                       <td colspan="3" style="text-align: center">Strong</td>
                       <td colspan="2"></td>
                       <td colspan="3" style="text-align: center">Weak</td>
                     </tr>
                     <tr>
                       <td colspan="3" style="text-align: center">[Look for Demand]</td>
                       <td colspan="2"></td>
                       <td colspan="3" style="text-align: center">[Look for Supply]</td>
                     </tr>
                   </thead>
                   <tbody>
                    <tr v-for="item in iema21ana" v-bind:key="item.index">
                      <td :class="{strong: item[0]}">{{item[0]}}</td> 
                      <td :class="{strong: item[1]}">{{item[1]}}</td>
                      <td :class="{strong: item[2]}">{{item[2]}}</td>
                      <td :class="{middle: item[3]}">{{item[3]}}</td>
                      <td :class="{middle: item[4]}">{{item[4]}}</td>
                      <td :class="{weak: item[5]}">{{item[5]}}</td>
                      <td :class="{weak: item[6]}">{{item[6]}}</td>
                      <td :class="{weak: item[7]}">{{item[7]}}</td> 
                      <td>{{item[8]}}</td>
                    </tr>
                   </tbody>
                   <tbody>
                    <tr v-for="item in iusdpcthrly" v-bind:key="item.index">
                      <td :class="{strong: item[0]}">{{item[0]}}</td> 
                      <td :class="{strong: item[1]}">{{item[1]}}</td>
                      <td :class="{strong: item[2]}">{{item[2]}}</td>
                      <td :class="{middle: item[3]}">{{item[3]}}</td>
                      <td :class="{middle: item[4]}">{{item[4]}}</td>
                      <td :class="{weak: item[5]}">{{item[5]}}</td>
                      <td :class="{weak: item[6]}">{{item[6]}}</td>
                      <td :class="{weak: item[7]}">{{item[7]}}</td> 
                      <td>{{format_dateTimeOnly(pIso(item[8]))}}</td>
                    </tr>
                   </tbody>
        </table>
                  <table class="table table-hover caption-top">
                   <caption>USD PCT Strong / Weak Analysis | {{format_dateLong(iusdpcttime)}}</caption> 
                   <thead>
                     <tr>
                       <td colspan="3" style="text-align: center">Strong</td>
                       <td colspan="2"></td>
                       <td colspan="3" style="text-align: center">Weak</td>
                     </tr>
                   </thead>
                   <tbody>
                    <tr v-for="item in iusdpct" v-bind:key="item.index">
                      <td :class="{strong: item[0]}">{{item[0]}}</td> 
                      <td :class="{strong: item[1]}">{{item[1]}}</td>
                      <td :class="{strong: item[2]}">{{item[2]}}</td>
                      <td :class="{middle: item[3]}">{{item[3]}}</td>
                      <td :class="{middle: item[4]}">{{item[4]}}</td>
                        <td :class="{weak: item[5]}">{{item[5]}}</td>
                        <td :class="{weak: item[6]}">{{item[6]}}</td>
                        <td :class="{weak: item[7]}">{{item[7]}}</td> 
                      <td>{{format_dateTimeOnly(pIso(item[8]))}}</td>
                    </tr>
                   </tbody>
        </table>
              </div>
              <div class="col"></div>
          </div>
        </div>
    </div>
</div>
</template>
<script>
// Add light dark button
// Add font big or small 
// change hover color
import axios from "axios";
import { AuthenticationState } from 'vue-auth0-plugin';
// import { memoize } from "lodash";
import { format , utcToZonedTime } from "date-fns-tz";
import {parseISO} from "date-fns";
// import moment from "moment-timezone"
//var fxbookurl = 'https://api.heatmapfx.com/fxbook';
export default ({
  info : "heatmap",
  el: '#app',
  inject: ['auth'],
  data () {
    return {
      info: null,
      timeSt: null,
      ioprBR: null,
      ioprBRTime: null,
      iadr: null,
      iadrTime: null,
      iema21ana: null,
      iema21anaTime: null,
      iusdpct: null,
      iusdpcthrly: null,
      iusdpcttime: null,
      etz: null,
      mtime: null,
      ibetatesters: null,
      ispecialtesters: null,
      itboneUsers: null,
      ipaidMembers: null,
      ipremiumMembers: null,
      authstate: AuthenticationState,
      isinbeta: false,
      isinspecial: false,
      isinTbone: false,
      isinpaid: false,
      isinprem: false
     }
  },
  methods: {
    gtm: function(){
        this.data5 = this.$gtm.enabled();
        // console.log(this.data5)
    },
    fxbook: function(){
      axios.get(this.$apihostname+'/heatmap')
      .then(response => {this.info = response.data});
    },
    fxbooktime: function(){
      // axios.get(this.$apihostname+'/heatmapTime')
      return axios.get(this.$apihostname+'/heatmapTime')
    //  .then(function(response){this.timeSt => response.data[0].time});
      // .then(response => {this.timeSt = moment.utc(response.data[0].time).tz(moment.tz.guess(true)).format('dddd MMMM Do YYYY, h:mm a z')});
      // .then(response => {this.timeSt = response.data[0].time});
      // return this.timeSt
    },
    oprBr: function(){
      // axios.get(this.$apihostname+'/oprBR')
      // .then(response => {this.ioprBR = response.data});
      axios.get(this.$apihostname+'/oprBR')
      .then(response => response.data)
      .then(data => {
        this.ioprBR = data
        this.ioprBRTime = data[0].time
        // console.log(data)
        // console.log(data[0].time)
        })
    },
    adr: function(){
      axios.get(this.$apihostname+'/ADRLeft')
      .then(response => response.data)
      .then(data => {
        this.iadr = data
        this.iadrTime = data[12].time+"Z"
         // console.log(data)
      })
    },
    ema21: function(){
      axios.get(this.$apihostname+'/ema21ana')
      .then(response => response.data)
      .then(data => {
        this.iema21ana = data
        this.iema21anaTime = parseISO(data[0].time+"Z")
        // console.log(this.iema21anaTime)
        // console.log(data)
      })
    },
    usdpct: function(){
      axios.get(this.$apihostname+'/usdpct')
      .then(response => response.data)
      .then(data => {
        this.iusdpct = data
        this.iusdpcttime = parseISO(data.zerhr[8]+"Z")
        })
    },
    usdpcthrly: function(){
      axios.get(this.$apihostname+'/usdpcthrly')
      .then(response => {this.iusdpcthrly = response.data});
    },
    dateup: function(){
      this.fxbooktime().then(response => {
        this.etz = parseISO(response.data[0].time+"Z")
        // console.log(format(this.etz, 'yyyy'))
        })
    },
    bdate: function(){
      // this.browserDate = moment.utc()
      this.browserDate = new Date()
    },
    betatesters: function(){
      axios.get(this.$apihostname+'/np/betatesters')
      .then(response => response.data)
      .then(data => {
        this.ibetatesters = data
        this.isinbeta = data.includes(this.auth.user?.sub.slice(15,33))
        })
      },
    specialtesters: function(){
      axios.get(this.$apihostname+'/np/specialtesting')
      .then(response => response.data)
      .then(data => {
        this.ispecialtesters = data
        this.isinspecial = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    paidMembers: function(){    /// Paid Basic Members
      axios.get(this.$apihostname+'/np/paidMembers')
      .then(response => response.data)
      .then(data => {
        this.ipaidMembers = data
        this.isinpaid = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    premiumMembers: function(){    /// Premium Members
      axios.get(this.$apihostname+'/np/premiumMembers')
      .then(response => response.data)
      .then(data => {
        this.ipremiumMembers = data
        this.isinprem = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    tboneUsers: function(){    /// tbone users
      axios.get(this.$apihostname+'/np/tboneUsers')
      .then(response => response.data)
      .then(data => {
        this.itboneUsers = data
        this.isinTbone = data.includes(this.auth.user?.sub.slice(15,33))
       })
      },
    // format_date(value){
    //      if (value) {
    //       //  return moment.utc(value).tz(moment.tz.guess(true)).format('MM/DD/YYYY, h:mmaz')
    //       return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),'MM/dd/yyyy, h:mm a z')
    //       }
    //   },
      // format_dateOnly(value){
      //    if (value) {
      //     //  return moment.utc(value).tz(moment.tz.guess(true)).format('MM/DD/YYYY')
      //     return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),'MM/dd/yyyy')
      //     }
      // },
    format_dateLong(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('ddd MMMM Do YYYY, h:mm a z')
          return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),'MMMM dd yyyy, h:mm a z')
          }
      },
    format_dateTimeOnly(value){
         if (value) {
          //  return moment.utc(value).tz(moment.tz.guess(true)).format('h:mmaz')
          return format(utcToZonedTime(value,Intl.DateTimeFormat().resolvedOptions().timeZone),'h:mmaz')
          }
      },
      pIso(value){
      if(value){
        return parseISO(value+"Z")
      }
      }
    // format_date(value){
    //      if (value) {
    //        return moment.utc(value).tz(moment.tz.guess(true)).format('MM/DD/YYYY, h:mmaz')
    //       }
    //   },
    // format_dateLong(value){
    //      if (value) {
    //        return moment.utc(value).tz(moment.tz.guess(true)).format('ddd MMMM Do YYYY, h:mm a z')
    //       }
    //   },
    // format_dateTimeOnly(value){
    //      if (value) {
    //        return moment.utc(value).tz(moment.tz.guess(true)).format('h:mmaz')
    //       }
    //   }
  },
  created(){
    this.fxbook()
    this.fxbooktime()
    this.oprBr()
    this.adr()
    this.ema21()
    this.usdpct()
    this.usdpcthrly()
    this.dateup()
    this.bdate()
    this.gtm()
    this.betatesters()
    this.specialtesters()
    this.paidMembers()
    this.premiumMembers()
    this.tboneUsers()
    // this.tzguess()
    // this.tyg()
    // this.convertDate()
  },
    mounted(){
    this.interval = setInterval(() => {
        this.fxbook()
        this.fxbooktime()
        this.oprBr()
        this.adr()
        this.ema21()
        this.usdpct()
        this.usdpcthrly()
        this.dateup()
        this.bdate()
        this.betatesters()
        this.specialtesters()
        this.paidMembers()
        this.premiumMembers()
        this.tboneUsers()
        // this.tzguess()
    }, 30000 );
  },
   computed: {
        authLevel: function() {
          //  const authp = injectAuth();
            if(
               this.isinspecial === true                
               ){
             return 4
               }
            else if(
               this.isinbeta === true
               ){
              return 3
            }
            else if(
               this.isinprem === true        
               ){
              return 2
            }
            else if(
               this.isinpaid  === true      
               ){
              return 1
            }
            else if(this.isinTbone === true || this.isinpaid === true){
              return 0
            }
            else return 5
        }
   }
})
</script>
<style scoped>
.highlight{
  background: #fcce01;
}
.table{
  font-size: 12px;
}
table td {
  width: 25%;
  /* align: left; */
}
.adrGreen{
  background: #8babd3;
}
.adrRed{
  background: #ff6666;
}
.strong{
  background: #8babd3;
}
.middle{
  background: #f9f3e9;
}
.weak{
  background: #ff6666 ;
}
.table-hover> tbody> tr:hover{
    background-color:yellow;
}
.datestyle {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: center;
    font-size: 12px;
    display: inline-block;
}
</style>
